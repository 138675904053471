import {defineStore} from 'pinia'
import {computed, ref} from 'vue'
import {useStrapi, useAsyncData} from "#imports";
import {useRouter} from 'nuxt/app'
//import {usePageTitle} from "@/composables/usePageTitle";

export const usePageStore = defineStore('page', () => {
    const pageData = ref(null)
    const pageLoaded = ref(false)
    const pageDoesNotExist = ref(false)
    const currentPagePath = ref(null)
    const redirectPath = ref(null)

    //const pageTitle = usePageTitle()

    async function fetchPageData(slugs = [], singleType = null) {
        pageLoaded.value = false
        redirectPath.value = null

        const contentType = singleType ?? 'pages'
        let filters = {}

        if (!singleType) {

            //last element of the slug array
            const slug = computed(() => slugs[slugs.length - 1])

            // build recursive filter for parent pages
            const buildParentFilter = (slugArray) => {
                if (slugArray.length < 2) return {}
                return {
                    Ueberseite: {
                        url: {$eq: slugArray[slugArray.length - 2]},
                        ...buildParentFilter(slugArray.slice(0, -1))
                    }
                }
            }

            const parentFilter = computed(() => buildParentFilter(slugs))

            filters = {
                $and: [
                    {url: {$eq: slug.value}},
                    parentFilter.value
                ]
            }
        }

        //const nuxtApp = useNuxtApp()
        await useAsyncData(() => useStrapi().find(contentType, {populate: 'deep,3', filters: filters}), {
            // this will not refetch if the key exists in the payload
            //getCachedData: key => nuxtApp.payload.static[key] ?? nuxtApp.payload.data[key]
        }).then(response => {
            const data = response.data?.value?.data ?? null
            pageData.value = Array.isArray(data) ? (data[0] ?? null) : data

            if (pageData.value?.attributes?.Weiterleiten) {
                const firstChild = pageData.value?.attributes?.Unterseiten?.data[0] ?? null;
                if (firstChild) {
                    redirectPath.value = '/' + slugs.join('/') + '/' + firstChild.attributes?.url;
                }
            }

            pageDoesNotExist.value = (pageData.value === null)
            //setPageTitle()
            pageLoaded.value = true

        }).catch(error => {
            console.log(error);
            if (error.error && error.error.status === 404) {
                pageDoesNotExist.value = true
                pageData.value = null
                pageLoaded.value = true
            }
        })
    }

    /*function setPageTitle() {
        const title = pageData.value?.attributes?.Titel ?? null
        if (!title) {
            pageTitle.value = null
            return
        }

        const federalState = pageData.value?.attributes.Ueberseite.data?.attributes.Bundesland.data?.attributes.Kuerzel ?? null
        if (federalState) {
            pageTitle.value = `${federalState} ${title}`
        } else {
            pageTitle.value = title
        }
    }*/

    function resetPageData() {
        pageData.value = null
        pageLoaded.value = false
        pageDoesNotExist.value = false
    }

    return {
        pageData,
        pageLoaded,
        pageDoesNotExist,
        currentPagePath,
        redirectPath,
        resetPageData,
        fetchPageData,
    }
})
